<template>
<v-layout row class="contactus-ovverly">
  <v-container  >
<v-layout column="" wrap>
    <v-row class="mb-6"></v-row>
  
    
   

<!-- 
   <v-layout row wrap class="mb-6">
   <v-flex md1>
            <v-img 
 src="../assets/images/enjaz.jpg"
 width="100"
 height="100">
</v-img>
   </v-flex>

<v-flex md4>
    <br>
      <span style="padding-right:10px;margin-top:10px;">شركة انجاز</span><br>
    <span style="padding-right:10px;margin-top:10px;">لتكنولوجيا المعلومات المحدودة</span>
</v-flex>



  
 



</v-layout> -->


<v-row class="mb-6"   justify="center" no-gutters>
      
<v-col xs="4"  lg="10">
  <v-card class="about_card">
    <span>من نحن :</span><br><br>
    <span>       منصة تكتاتي هي منصة تمكن الزبائن من الحجز في الخدمات المسجلة لديها و تشمل خدمات كثيرة منها الحجوزات الطبية
              و
              الهندسية و حجوزات قاعات الاعراس و المؤتمرات و الخفلات و الندوات العلمية
              منصة حجوزات هي منصة تمكن الزبائن من الحجز في الخدمات المسجلة لديها و تشمل خدمات كثيرة منها الحجوزات الطبية
              و
              الهندسية و حجوزات قاعات الاعراس و المؤتمرات و الخفلات و الندوات العلمية</span>
</v-card>


</v-col>

</v-row>





<v-row class="mb-6"   justify="center" no-gutters>
      
<v-col  lg="10">
  <v-card class="about_card">
       <span>  السياسة العامة </span><br><br>
    <span>       منصة تكتاتي هي منصة تمكن الزبائن من الحجز في الخدمات المسجلة لديها و تشمل خدمات كثيرة منها الحجوزات الطبية
              و
              الهندسية و حجوزات قاعات الاعراس و المؤتمرات و الخفلات و الندوات العلمية
              منصة حجوزات هي منصة تمكن الزبائن من الحجز في الخدمات المسجلة لديها و تشمل خدمات كثيرة منها الحجوزات الطبية
              و
              الهندسية و حجوزات قاعات الاعراس و المؤتمرات و الخفلات و الندوات العلمية</span>
</v-card>


</v-col>

</v-row>

</v-layout>
  </v-container>
</v-layout>

</template>
<script>
export default {
      data: () => ({
          text:" شركة انجاز هي شركة رائدة في مجال التحول من الأنظمة الورقية التقليدية الى الأنظمة الرقمية الالكترونية، حيث ان الشركة تقوم من خلال "
                +" تكنولوجيامتقدمة في وضع البنى التحتية الكفيلة بهذا الانتقال ومن ثم تقديم الخدمات بشكل الكتروني يضمن الرفاهية والسهولة و"+
               
                "الشفافية في تقييم جودة الخدمة.انجاز تعمل بأسلوب متفرد في توفير الأدوات التي تساعد السوق العراقية ودوائر ومؤسسات الحكومة "+
                " في محاكاة الأنظمة العالمية في تقديم الخدمات لزبائنها ومواطنيها، كما ان الشركة ونظرا للوضع الأمني الخاص الذي يمر به العراق والعالم " +
                "تقوم بتعريف مستخدمي الخدمات ومجهزيها من خلال البصمات البايومترية المتعددة والتي تضمن تعريف الزبون بشكل لا يقبل الشك"
            ,
            privec:"ينصب اهتمام شركة إنجاز على أن تكون واحدة من الشركات البارزة في العراق من خلال الخدمات المُتميزة التي تقدمها حيث تحاول أن تساهم في تكبير قاعدة الإستخدام الإلكتروني داخل القُطر وخارجه."+
                      "واحدة من الأهداف التي تحاول شركة إنجاز الوصول لها هي ترك بصمة في مجال تكنولوجيا المعلومات وذلك من خلال تأسيس مواقع ونوافذ وخوادم بتبادل الكتروني متميز يضعها في مصاف شركات البرمجيات المُطوّرة على مستوى المنطقة."+

                      "كما تسعى الشركة إلى توفير خدمات الكترونية ميسرة ومتطورة تساهم في تعزيز تكنولوجيا المعلومات في العراق والعالم العربي. وتحرص الشركة على إدامة الحوار والتواصل مع الشركات العربية والدولية من أجل ربط العراق بكل ما يحصل من تحديثات وثورات رقمية و طفرات تكنولوجية الكترونية مواكبة بذلك ركب العالم الالكتروني."+
                         "كما إن الشركة تعمل ليل نهار على تدعيم تواجدها في السوق المحلي كشركة متخصصة في تطوير البرمجيات وجعل اسمها متداولا ً في السوق لخلق سمعة طيبة وموثوق بها على الصعيد المحلي والعربي."
      })
   }
</script>